<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:show-sort="showSort" :can-ad-sort="true" :data-list="fromInfo" @sort="onSort")
  .row
    .col-lg-12
      router-link(to='/')
        b-button.width-md.mb-2(variant="outline-secondary") 
          //- i.ri-arrow-left-fill.back-icon
          span 回上一層

      .card
        ul.nav.nav-tabs()
          li.nav-item(
            :key="tab.id" 
            :title="tab.title" 
            :id="'tab_' + index" 
            v-for="(tab,index) in tabsData"
            draggable="true"
            @dragstart="startDrag($event, index)"
            @drop="onDrop($event, index)"
            @dragend="endDrag($event, index)"
            @dragenter.prevent
            @dragover.prevent
          )
            a.nav-link(
              :class="{'active' : index === tabIndex}" 
              :id="tab.id"
              @click.prevent="tabIndex = index"
            )
              b {{ tab.title }}
          .nav-link(role="presentation" @click.prevent="newTab" href="#")
            b 點選新增分頁 ＋
        .card-body.mt-3
          FastImport(
            v-if="tabsData[tabIndex].id"
            :region-type="8"
            :target-id="tabsData[tabIndex].id"
            :show-sub-type="false" 
            :import-count-rule="checkRule" 
            :show-max-count="true"
            :max-count="21"
            @import="onFastImportNewsList", 
          )
          .row.mb-3
            .col-8.col-lg-4
              input.form-control(v-model="tabName" type="text", placeholder="請輸入區塊標題")  
            .col-1
              b-button.ml-0(v-if="showCancelAreaButton" variant="danger" size="md" @click.prevent="onCancelArea")
                i.fe-trash-2 
          .row
            .col
              b-button.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="onSortClick") 編輯排序
          .row  
            .col 
              .card.col-lg-9(v-for="(item,index) of fromInfo")
                newsItem(
                  :key="`region-${tabsData[tabIndex].id}-NewsItemList`"
                  :canUseAd="true"
                  :regionId="tabsData[tabIndex].id"
                  :item="item" 
                  :index="index" 
                  @setItemData="setItemData"
                  @removeItem="deleteOne"
                )
          .row
            .col
              b-button.col-lg-9.add-btn.width-lg.mb-2(variant="outline-primary" @click="addNewOne(3)") ＋新增 3 則 
        .row.m-2 
          .col
            b-button.action-btn.width-lg.mr-1(variant="primary" @click="checkTotalCount('save')" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
            b-button.action-btn.width-lg(variant="primary" @click="checkTotalCount('release')" :disabled="releasing") {{ releasing ? '發佈中...' : '發佈' }}

</template>


<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import newsItem from "@/components/news-item/news-item"
import Sort from "@/components/sort"
import FastImport from '@/components/FastImport'
import {
  checkFromData,
  formatRegionNewsList,
  cleanUpData,
  formatImportNews,
  RegionSettingSchema
} from '@/views/pages/marketing-slot/home-index/common.js'


/**
 * Starter component
 */
export default {
  name: 'HomeRegion03',
  data () {
    return {
      title: "三宮格區",
      items: [
        {
          text: "行銷版位管理",
          href: "/",
        },
        {
          text: "大首頁",
          href: "/",
        },
        {
          text: "三宮格區",
          active: true,
        }
      ],
      fromInfo: [],
      tabsData: [],
      tabIndex: 0,
      tabName: '',
      showSort: true,
      isDragging: false,
      saving: false,
      releasing: false,
    };
  },
  watch: {
    tabIndex (_val, _old) {
      if (this.tabsData.length > 0 && !this.isDragging) {
        this.getThreeAreaSetting(this.tabsData[_val].id)
      }
      this.tabName = ''
    },
    tabName (_val) {
      if (_val.trim() === '') return
      const updatedTab = {
        ...this.tabsData[this.tabIndex],
        title: _val.trim()
      }
      this.tabsData.splice(this.tabIndex, 1, updatedTab)
    }
  },
  mounted () {
    this.getThreeAreaList()
  },
  computed: {
    currentTabName () { return this.tabsData[this.tabIndex].title },
    currentTabHashId () { return this.tabsData[this.tabIndex].id },
    showCancelAreaButton () { return this.tabsData.length > 1 }
  },
  methods: {
    addNewOne (_count = 1) {
      const addItem = Array.from({ length: _count }, _item => ({ ...RegionSettingSchema }));
      this.fromInfo = this.fromInfo.concat(addItem)
    },
    deleteOne (_index) {
      this.fromInfo.splice(_index, 1)
    },
    setItemData (_setItem, _index) {
      this.fromInfo.splice(_index, 1, _setItem)
    },
    // 新增一個三宮格區塊
    newTab () {
      this.$root.apis.createThreeArea({
        name: '三宮格區塊'
      }, this.createThreeAreaSuccess, this.createThreeAreaError)
    },
    createThreeAreaSuccess (_response) {
      this.tabsData.push({
        id: _response.body.data,
        title: '三宮格區塊'
      })
      this.$nextTick(function () {
        this.tabIndex = this.tabsData.length - 1
      })
    },
    createThreeAreaError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },
    // 刪除一個三宮格區塊
    onCancelArea () {
      const vm = this
      this.$root.common.confirmAction("刪除此區塊", "是否確定要刪除？刪除即不可復原喔！", "刪除", "再想一下",
        function (_result) {
          vm.onCancelConfirmed()
        }
      )
    },
    onCancelConfirmed () {
      this.$root.apis.cancelThreeArea(this.currentTabHashId, this.cancelThreeAreaSuccess, this.cancelThreeAreaError)
    },
    cancelThreeAreaSuccess (_response) {
      this.tabsData.splice(this.tabIndex, 1)
      this.tabIndex = this.tabIndex - 1 < 0 ? 0 : this.tabIndex - 1
      this.getThreeAreaSetting(this.tabsData[this.tabIndex].id)
    },
    cancelThreeAreaError (_error) {
      console.log(_error)
    },
    // 取得三宮格 tab 列表
    getThreeAreaList () {
      this.tabsData = false
      this.$root.apis.getThreeAreaList(this.getThreeAreaListSuccess, this.getThreeAreaListError)
    },
    // 獲取三宮格列表成功
    getThreeAreaListSuccess (_response) {
      this.tabsData = _response.body.data
      if (this.tabsData.length > 0) {
        this.getThreeAreaSetting(this.tabsData[0].id)
      }
    },
    // 獲取三宮格列表失敗
    getThreeAreaListError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },
    // 取得三宮格分頁設定
    getThreeAreaSetting (_regionHashId) {
      this.fromInfo = false
      this.$root.loading = true
      this.$root.apis.getThreeAreaSetting(_regionHashId, this.getThreeAreaSettingSuccess, this.getThreeAreaSettingError)
    },
    getThreeAreaSettingSuccess (_response) {
      this.$root.loading = false
      if (_response.body.data.id !== this.currentTabHashId) return
      this.fromInfo = formatRegionNewsList(_response.body.data.news)
    },
    getThreeAreaSettingError (_error) {
      let error = _error.body.data
      this.$root.loading = false
      this.$root.common.showErrorDialog(error)
    },
    // 檢查新聞總數為 3 的倍數
    checkTotalCount (_type) {
      const vm = this
      const currentCount = vm.fromInfo.length
      if (currentCount % 3 === 0) {
        _type === 'save' ? vm.saveRegionData() : vm.releaseAreaSetting()
      } else {
        vm.$root.common.confirmAction("新聞總數未滿 3 的倍數", "是否要設定為 3 的倍數", "設定為 3 的倍數", "先不用",
          // 設定為 3 的倍數
          function (_result) {
            const remainder = Math.ceil(currentCount / 3) * 3 - currentCount
            vm.addNewOne(remainder)
          },
          // 先不用
          function (_result) {
            _type === 'save' ? vm.saveRegionData() : vm.$root.common.showErrorNotify('必須為 3 的倍數才能發佈！')
          }
        )
      }
    },
    // 請求更新分區資料
    saveRegionData () {
      const vm = this
      if (!checkFromData(vm, this.fromInfo)) {
        return
      }
      let setData = {
        name: this.currentTabName,
        items: cleanUpData(this.fromInfo)
      }

      this.saving = true
      this.$root.apis.saveThreeAreaSetting(this.currentTabHashId, setData, this.processSaveSuccess, this.processSaveError)
    },
    processSaveSuccess () {
      this.$root.common.showSingleNotify(this.currentTabName + "儲存成功！")
      this.saving = false
    },
    processSaveError (_error) {
      console.error('processSaveError', _error)
      this.$root.common.showErrorNotify(_error.body.data)
      this.saving = false
    },
    // 請求發布分區資料
    releaseAreaSetting () {
      this.releasing = true
      this.$root.apis.releaseThreeAreaSetting(this.currentTabHashId, this.processReleaseSuccess, this.processReleaseError)
    },
    processReleaseSuccess () {
      this.$root.common.showSingleNotify(this.currentTabName + "發布成功！")
      this.releasing = false
    },
    processReleaseError (_error) {
      this.$root.common.showErrorNotify(_error.body.data)
      this.releasing = false
    },
    onSortClick () {
      this.showSort = true
    },
    onSort (_sorted) {
      this.fromInfo = _sorted
      this.showSort = false
    },
    // 自動帶入
    checkRule (_count) {
      if (!(_count % 3 === 0)) {
        this.$root.common.showErrorNotify("只能帶入三的倍數")
        return false
      }
      return true
    },
    onFastImportNewsList (_news) {
      console.log('=> 接收自動帶入的新聞清單: ', _news)
      this.fromInfo = _news.map(_article => {
        return formatImportNews(_article)
      })
    },
    startDrag (_evt, _index) {
      this.isDragging = true
      _evt.dataTransfer.dropEffect = 'move'
      _evt.dataTransfer.effectAllowed = 'move'
      _evt.dataTransfer.setData('startIndex', _index)
    },
    onDrop (_evt, _dropIndex) {
      const startIndex = _evt.dataTransfer.getData('startIndex')
      this.changeTabOrder(startIndex, _dropIndex)
    },
    endDrag (_evt, _index) {
      this.isDragging = false
    },
    changeTabOrder (_startIndex, _dropIndex) {
      const moveTab = this.tabsData.splice(_startIndex, 1)[0]
      this.tabsData.splice(_dropIndex, 0, moveTab)
      const data = {
        items: this.tabsData.map(({ id, title }) => ({ id, title }))
      }
      this.changeSort(data)
      this.tabIndex = _dropIndex
    },
    changeSort (_data) {
      this.$root.apis.changeThreeAreaSort(
        _data,
        function (_response) { },
        function (_error) {
          console.error(_error)
        }
      )
    },
  },
  components: {
    Layout,
    PageHeader,
    newsItem,
    Sort,
    FastImport
  },
};
</script>

<style lang="sass" scoped>
::v-deep nav
  display: flex
  flex-wrap: wrap
  padding-left: 0
  margin-bottom: 0
  list-style: none

::v-deep.nav-tabs
  border-bottom: 1px solid #dee2e6
  .nav-link
    cursor: pointer
    font-weight: 400
    font-size: 15px
    padding: 0.5rem 1rem
    margin-bottom: -1px
    background-color: transparent
    border: 1px solid transparent
    border-top-left-radius: 0.25rem
    border-top-right-radius: 0.25rem
    &.active
      color: #6c757d
      background-color: #fff
      border-color: #dee2e6 #dee2e6 #fff

.back-icon
  margin-right: 5px
  position: relative
  font-size: 14px
  top: 1.2px
.add-btn
  width: 100%
  border: dashed 1px

::v-deep.vue-dropzone
  padding: 0
::v-deep.dropzone-previews
  height: 170px

  .preview-img
    width: 100%
    height: 100%
    max-height: 170px
    object-fit: cover
  .img-del-btn
    position: absolute
    right: -15px
    top: -15px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer

.card
  margin-bottom: 2rem
  .form-group
    display: flex
    justify-content: space-start
    align-items: center
    flex-wrap: wrap
    gap: 14px
    .search-box
      position: relative
      #tip
        padding: 0 10px 0 35px
        background-repeat: no-repeat
        background-position: 10px 7px
      .fa-search
        position: absolute
        top: 30%
        margin: auto
    .del-btn
      flex: 1
      display: flex
      justify-content: flex-end
      align-items: center
      .btn
        padding: 0rem .3rem
        border-radius: 50px
::v-deep .tab-pane
  width: 100%

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 1rem 0
</style>